import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import DynamicLinkPage from './pages/DynamicLinkPage';
import PrivacyPolicy from './components/privacy';

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='dynamicLink' element={<DynamicLinkPage />} />
          <Route path='privacy' element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
