export const Image = ({ title, text, largeImage, smallImage }) => {
  return (
    <div className='portfolio-item'>
      <div className='hover-bg'>
        {' '}
        <a href={largeImage} title={title} data-lightbox-gallery='gallery1'>
          <div className='hover-text'>
            <h4>{title}</h4>
            <br />
            <p>{text}</p>
          </div>
          <img
            style={{
              height: '400px',
            }}
            src={smallImage}
            className='img-responsive'
            alt={title}
          />{' '}
        </a>{' '}
      </div>
    </div>
  );
};
