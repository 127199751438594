import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const options = [
  { label: 'Es', value: 'es', flag: 'img/español.png' },
  { label: 'En', value: 'en', flag: 'img/ingles.png' },
];

export const Navigation = (props) => {
  const style = {
    paddingTop: 10,
    margin: '0 20',
  };
  const [t, i18n] = useTranslation('global');

  const handleSelectChange = (event) => {
    i18n.changeLanguage(event.value);
  };

  return (
    <nav
      id='menu'
      className='navbar navbar-default navbar-expand-lg navbar-fixed-top'
    >
      <a className='navbar-brand' href='#'>
        BodegAlert
      </a>
      <div className='navbar-header'>
        <button
          type='button'
          className='navbar-toggle'
          data-toggle='collapse'
          data-target='#bs-example-navbar-collapse-1'
        >
          <span className='icon-bar'></span> <span className='icon-bar'></span>{' '}
          <span className='icon-bar'></span>{' '}
        </button>
      </div>
      <div
        style={{ marginRight: 20 }}
        className='collapse navbar-collapse pull-right'
        id='bs-example-navbar-collapse-1'
      >
        <ul className='nav navbar-nav float-right'>
          <li className='nav-item active'>
            <a className='nav-link' href='#about'>
              {t('navigation.button1')}{' '}
              <span className='sr-only'>(current)</span>
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#services'>
              {t('navigation.button2')}
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#features'>
              {t('navigation.button3')}
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#bikeAlert'>
              {t('navigation.button6')}
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#portfolio'>
              {t('navigation.button4')}
            </a>
          </li>
          {/* <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li> */}
          <li className='nav-item'>
            <a className='nav-link' href='#contact'>
              {t('navigation.button5')}
            </a>
          </li>
          <li className='nav-item'>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: 'transparent',
                  color: 'white',
                  borderColor: 'transparent',
                }),
              }}
              defaultValue={options[0]}
              options={options}
              onChange={(e) => handleSelectChange(e)}
              formatOptionLabel={(option) => (
                <div style={{ color: 'white', fontSize: 12, marginTop: 5 }}>
                  {option.flag ? (
                    <img
                      style={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        marginRight: '10px',
                      }}
                      alt='flag'
                      src={option.flag}
                    />
                  ) : (
                    ''
                  )}
                  {option.label}
                </div>
              )}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};
